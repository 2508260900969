import { Chain, ChainId, ChainInfo, ChainType, ParticleChains } from './types';

export function getChainInfo(chain: Chain): ChainInfo | undefined {
    const chainKey = Object.keys(ParticleChains).find((key) => {
        const itemChain = ParticleChains[key];
        return itemChain?.id === chain.id && itemChain?.name?.toLowerCase() === chain.name.toLowerCase();
    });
    if (!chainKey) return undefined;

    return ParticleChains[chainKey];
}
export function getChainNetwork(chain: Chain): string {
    const target = getChainInfo(chain);
    return target?.network || 'UNKNOWN';
}
export function getChainSymbol(chain: Chain): string {
    const target = getChainInfo(chain);
    return target?.nativeCurrency?.symbol ?? 'UNKNOWN';
}

export function getChainType(chain: Chain): ChainType | undefined {
    const target = getChainInfo(chain);
    return target?.chainType as ChainType;
}

export function isChainSupportEIP1559(chain: Chain): boolean {
    const target = getChainInfo(chain);
    return target?.supportEIP1559 ?? false;
}

export function getChainIcon(chain: Chain): string | undefined {
    const target = getChainInfo(chain);
    return target?.icon;
}

export function getEVMChainInfoById(id: ChainId): ChainInfo | undefined {
    const chainKey = Object.keys(ParticleChains).find((key) => {
        const itemChain = ParticleChains[key];
        return itemChain?.id === id && itemChain.chainType === 'evm';
    });
    if (!chainKey) return undefined;

    return ParticleChains[chainKey];
}

export function getSolanaChainInfoById(id: ChainId): ChainInfo | undefined {
    const chainKey = Object.keys(ParticleChains).find((key) => {
        const itemChain = ParticleChains[key];
        return itemChain?.id === id && itemChain.chainType === 'solana';
    });
    if (!chainKey) return undefined;

    return ParticleChains[chainKey];
}
