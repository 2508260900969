const html = `
  <button class="particle-pwe-btn">
    <img class="particle-pwe-img particle-pwe-wallet-icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" alt="" />
    <img class="particle-pwe-img particle-pwe-down-arrow particle-pwe-down-arrow-hide" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" alt="" />
  </button>
  <div class="particle-pwe-iframe-content">
  </div>
`;

const walletEntryRender = () => {
    const className = 'particle-wallet-entry-container';
    const el = document.querySelector('.' + className);
    el && el.remove();
    const EL = document.createElement('div');
    EL.classList.add(className);
    EL.innerHTML = html;
    document.body.appendChild(EL);
};

export default walletEntryRender;
