import { UserSimpleInfo } from '../types';
import { isParticleDev } from './utils';

const userSimpleInfo = async ({
    token,
    projectUuid,
    projectKey,
    projectAppUuid,
}: {
    token: string;
    projectUuid: string;
    projectKey: string;
    projectAppUuid: string;
}): Promise<UserSimpleInfo> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        let url = isParticleDev()
            ? `https://api-debug.particle.network/apps/${projectAppUuid}/user-simple-info`
            : `https://api.particle.network/apps/${projectAppUuid}/user-simple-info`;
        url += `?projectUuid=${projectUuid}&projectKey=${projectKey}&token=${token}`;
        xhr.open('GET', url, true);
        xhr.timeout = 30000;
        // xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            const response = JSON.parse(this.responseText);
            if (response.error_code) {
                reject(response);
            } else {
                resolve(response);
            }
        };
        xhr.onerror = function () {
            reject(this.statusText);
        };
        xhr.send();
    });
};

export default userSimpleInfo;
