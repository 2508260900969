export function bufferToHex(buf: Buffer): string {
    return `0x${buf.toString('hex')}`;
}

export function intToHex(value: number): string {
    return `0x${Number(value).toString(16)}`;
}

export function isHexPrefixed(str: string): boolean {
    if (typeof str !== 'string') {
        throw new Error(`[isHexPrefixed] input must be type 'string', received type ${typeof str}`);
    }

    return str[0] === '0' && str[1] === 'x';
}

export function addHexPrefix(str: string): string {
    if (typeof str !== 'string') {
        return str;
    }

    return isHexPrefixed(str) ? str : '0x' + str;
}

export function stripHexPrefix(str: string): string {
    if (typeof str !== 'string')
        throw new Error(`[stripHexPrefix] input must be type 'string', received ${typeof str}`);

    return isHexPrefixed(str) ? str.slice(2) : str;
}

/**
 * Is the string a hex string.
 *
 * @param  value
 * @param  length
 * @returns  output the string is a hex string
 */
export function isHexString(value: string, length?: number): boolean {
    if (typeof value !== 'string' || !value.match(/^0x[0-9A-Fa-f]*$/)) return false;

    if (typeof length !== 'undefined' && length > 0 && value.length !== 2 + 2 * length) return false;

    return true;
}
