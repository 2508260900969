import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

type DataEncoding = 'base64' | 'hex';

export function encryptUrlParam(params = {}, encoding: DataEncoding = 'base64'): string {
    const secretKey = uuidv4().replace(/-/g, '').toUpperCase();
    const cipherText = encryptData(params, secretKey, encoding);

    return cipherText + secretKey;
}

export function encryptData(data = {}, secretKey: string, encoding: DataEncoding = 'base64'): string {
    if (typeof data !== 'string') {
        data = JSON.stringify(data);
    }
    const keyWA = CryptoJS.enc.Utf8.parse(secretKey);
    const cipherText = CryptoJS.AES.encrypt(data as string, keyWA, {
        mode: CryptoJS.mode.ECB,
        algorithm: CryptoJS.algo.AES,
        padding: CryptoJS.pad.Pkcs7,
        formatter: CryptoJS.format.Hex,
    }).ciphertext;
    if (encoding === 'base64') {
        return encodeURIComponent(CryptoJS.enc.Base64.stringify(cipherText));
    } else {
        return CryptoJS.enc.Hex.stringify(cipherText).toUpperCase();
    }
}

export function decryptUrlParam(params: string, encoding: DataEncoding = 'base64'): string {
    if (params) {
        const secretKey = params.slice(-32);
        const data = params.slice(0, params.length - 32);
        return decryptData(data, secretKey, encoding);
    } else {
        return '';
    }
}

export function decryptData(params: string, secretKey: string, encoding: DataEncoding = 'base64'): string {
    let dec;
    if (encoding === 'base64') {
        dec = CryptoJS.enc.Base64.parse(params);
    } else {
        dec = CryptoJS.enc.Hex.parse(params);
    }
    const keyWA = CryptoJS.enc.Utf8.parse(secretKey);
    const bytes = CryptoJS.AES.decrypt(
        CryptoJS.lib.CipherParams.create({
            ciphertext: dec,
            key: keyWA,
            algorithm: CryptoJS.algo.AES,
            padding: CryptoJS.pad.Pkcs7,
            formatter: CryptoJS.format.Hex,
        }),
        keyWA,
        {
            mode: CryptoJS.mode.ECB,
        }
    );
    return bytes.toString(CryptoJS.enc.Utf8);
}
