import { AcitveLoginType, ActiveAction, BI } from '@particle-network/analytics';
import { UserInfo } from '../types';
import { isNullish } from './utils';

export function particleActive(
    bi: BI,
    chainId: number,
    walletAddress: string,
    userInfo: UserInfo,
    action: ActiveAction
) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { token, thirdparty_user_info, security_account, ...user } = userInfo;
    bi.active({
        chain_id: chainId,
        identity: user.uuid,
        login_type: AcitveLoginType.PARTICLE,
        action: action,
        wallet_address: walletAddress,
        user_info: JSON.stringify(user, (key, value) => {
            if (!isNullish(value)) return value;
        }),
    });
}
