import type { JsonRpcResponse, ParticleRpcRequest } from '@particle-network/auth';
import { isHttpUrl } from '@particle-network/auth';
import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { IJsonRpcConnection } from './types';
import type { ConnectionConfig } from './types';

export class HttpConnection extends IJsonRpcConnection {
    private api: AxiosInstance | undefined;

    private registering = false;

    constructor(private config: ConnectionConfig) {
        super();
        if (!isHttpUrl(config.url)) {
            throw new Error(`Provided URL is not compatible with HTTP connection: ${config.url}`);
        }
        this.config = config;
    }

    get connected(): boolean {
        return typeof this.api !== 'undefined';
    }

    get connecting(): boolean {
        return this.registering;
    }

    public async open(): Promise<void> {
        this.api = await this.register();
    }

    public async close(): Promise<void> {
        this.onClose();
    }

    public async send(request: ParticleRpcRequest): Promise<JsonRpcResponse> {
        if (typeof this.api === 'undefined') {
            this.api = await this.register();
        }
        return this.api.post('/', request).then((res) => res.data);
    }

    // ---------- Private ----------------------------------------------- //

    private async register(): Promise<AxiosInstance> {
        const connectConfig = this.config;
        if (!isHttpUrl(connectConfig.url)) {
            throw new Error(`Provided URL is not compatible with HTTP connection: ${connectConfig.url}`);
        }
        if (this.registering) {
            return new Promise((resolve, reject) => {
                this.events.once('open', () => {
                    if (typeof this.api === 'undefined') {
                        return reject(new Error('HTTP connection is missing or invalid'));
                    }
                    resolve(this.api);
                });
            });
        }
        this.registering = true;
        const api = axios.create({
            baseURL: connectConfig.url,
            timeout: 30_000, // 30 secs
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        api.interceptors.request.use(
            function (config) {
                if (config.headers) {
                    config.headers['Authorization'] = connectConfig.basicCredentials;
                } else {
                    config.headers = {
                        Authorization: connectConfig.basicCredentials,
                    };
                }

                if (!config.params) {
                    config.params = {};
                }
                config.params.chainId = config.data?.chainId ?? connectConfig.chainId();
                config.params.projectUuid = connectConfig.authentication.projectId;
                config.params.projectKey = connectConfig.authentication.clientKey;
                return config;
            },
            (error) => Promise.reject(error)
        );
        this.onOpen(api);
        return api;
    }

    private onOpen(api: AxiosInstance) {
        this.api = api;
        this.registering = false;
        this.events.emit('open');
    }

    private onClose() {
        this.api = undefined;
        this.events.emit('close');
    }
}
