import { controller } from '../controller';
import { en } from './en';
import { ja } from './ja';
import { ko } from './ko';
import { zh_CN } from './zh_CN';
import { zh_HK } from './zh_HK';

export default function locale() {
    const code = controller.getLanguage();
    if (code.startsWith('en')) {
        return en;
    } else if (code.startsWith('ja')) {
        return ja;
    } else if (code.startsWith('ko')) {
        return ko;
    } else if (code === 'zh_CN' || code === 'zh-CN' || code === 'zh') {
        return zh_CN;
    } else if (code === 'zh_HK' || code === 'zh-HK' || code === 'zh_TW' || code === 'zh-TW') {
        return zh_HK;
    } else {
        return en;
    }
}
