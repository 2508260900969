import { isParticleDev } from './utils';

const silenceLogout = async ({
    token,
    projectUuid,
    projectKey,
}: {
    token: string;
    projectUuid: string;
    projectKey: string;
}): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        let url = isParticleDev()
            ? 'https://api-debug.particle.network/fast_logout'
            : 'https://api.particle.network/fast_logout';
        url += `?projectUuid=${projectUuid}&projectKey=${projectKey}`;
        xhr.open('POST', url, true);
        xhr.timeout = 30000;
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            const response = JSON.parse(this.responseText);
            if (response.error_code) {
                reject(response);
            } else {
                resolve(response.success);
            }
        };
        xhr.onerror = function () {
            reject(this.statusText);
        };
        xhr.send(`token=${token}`);
    });
};

export default silenceLogout;
